import React from 'react';
import { useNavigate } from 'react-router-dom';

export function PlansLoggedOut() {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="lg:p-16 xl:p-24">
        <h1 className="nm-default-page-heading m-4">
          Pick the plan that&apos;s right for you
        </h1>
        <p className="text-center m-4 ">Simple plans for every use case.</p>

        <div className="flex m-auto justify-center">
          <div className="sm:grid sm:grid-cols-3 sm:gap-12">
            <div className="mt-4 sm:mt-0 rounded-md mx-4 border border-main-letters p-8 max-w-xs bg-main-backgroundFooter">
              <p className="mt-4"></p>
              <p className="font-heading text-3xl">Lifetime</p>
              <p className="my-4">Pay once, use forever.</p>
              <ul className="text-sm text-left list-disc list-inside text-main-letters">
                <li>
                  <span className="text-white">Track 15 URLs</span>
                </li>
                <li>
                  <span className="text-white">Checks every 2 hours</span>
                </li>
                <li>
                  <span className="text-white">Smart keywords</span>
                </li>
                <li>
                  <span className="text-white">3 channels</span>
                </li>
              </ul>
              <div className="divider divider-primary"></div>
              <p className="text-4xl font-bold font-heading text-center">
                $87.99<span className="text-sm"></span>
              </p>
              <p className="text-xs text-center">Use it forever.</p>
              <button
                className="btn w-full btn-primary text-white flex m-auto mt-4"
                onClick={() => navigate('/signup?next=/pricing')}
              >
                Get started
              </button>
            </div>

            <div className="mt-4 sm:mt-0 rounded-md mx-4 border border-main-letters p-8 max-w-xs bg-main-backgroundFooter relative">
              <p className="mt-4"></p>
              <div className="bg-main-letters w-full absolute top-0 left-0 p-2 rounded-t-sm">
                <p className="text-main-backgroundFooter font-bold text-sm">
                  Best Value
                </p>
              </div>
              <p className="font-heading text-3xl">Standard</p>
              <p className="my-4">If you track sites often.</p>
              <ul className="text-sm text-left list-disc list-inside text-main-letters">
                <li>
                  <span className="text-white">Track 12 URLs</span>
                </li>
                <li>
                  <span className="text-white">Checks every 2 hours</span>
                </li>
                <li>
                  <span className="text-white">Smart keywords</span>
                </li>
                <li>
                  <span className="text-white">3 notification channels</span>
                </li>
              </ul>
              <div className="divider divider-primary"></div>
              <p className="text-4xl font-bold font-heading text-center">
                $7.99<span className="text-sm">/month</span>
              </p>
              <p className="text-xs text-center">Cancel anytime.</p>
              <button
                className="btn w-full btn-primary text-white flex m-auto mt-4"
                onClick={() => navigate('/signup?next=/pricing')}
              >
                Get started
              </button>
            </div>
            <div className="mt-4 sm:mt-0 rounded-md mx-4 border border-main-letters p-8 max-w-xs bg-main-backgroundFooter">
              <p className="mt-4"></p>
              <p className="font-heading text-3xl">Starter</p>
              <p className="my-4">Try out for free.</p>
              <ul className="text-sm text-left list-disc list-inside text-main-letters">
                <li>
                  <span className="text-white">Track 3 URLs</span>
                </li>
                <li>
                  <span className="text-white">Checks every 12 hours</span>
                </li>
                <li>
                  <span className="text-white">Regular keywords</span>
                </li>
                <li>
                  <span className="text-white">Email notifications</span>
                </li>
              </ul>
              <div className="divider divider-primary"></div>
              <p className="text-4xl font-bold font-heading text-center">
                Free forever
              </p>
              <p className="text-xs text-center">No credit card needed.</p>
              <button
                className="btn w-full btn-primary text-white flex m-auto mt-4"
                onClick={() => navigate('/signup')}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-main-test1 text-main-background lg:p-24 mt-8">
        <div className="max-w-3xl m-auto lg:border-2 lg:rounded-xl border-main-background p-8">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            Not Sure About The Price?
          </p>
          <p className="text-xl xl:text-2xl text-center mt-4">
            We understand that! Why not sign up and give it a try?
            <br />
            There is always a free-forever plan you can test out as much as you
            want before you decide to upgrade.
          </p>
          <div className="flex m-auto my-8">
            <button
              className="btn btn-primary btn-wide m-auto"
              onClick={() => navigate('/signup')}
            >
              Try Free Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
