/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BillingInfo, PlanId } from '../../types';
import { BaseModal } from './Modal';
import { GetLifetimePlanUrl, GetStandardPlanUrl } from '../../api/lemonSqueezy';
import useApiCall from '../../hooks/UseApiCall';
import * as api from '../../api';

interface Props {
  name: string;
  description: string;
  features: string[];
  price: string;
  priceDescription: string;
  buttonText: string;
  buttonOnClick: () => void;
  modal: JSX.Element;
}

function DisabledPlan(props: Props) {
  return (
    <div className="mt-4 sm:mt-0 rounded-md mx-4 p-4 max-w-xs bg-gray-500 hover:cursor-not-allowed text-gray-200 relative">
      <p className="mt-8"></p>
      <p className="font-heading text-3xl">{props.name}</p>
      <p className="my-4">{props.description}</p>
      <ul className="text-sm text-left list-disc list-inside ">
        {props.features.map((feature, idx) => {
          return (
            <li key={idx}>
              <span className="">{feature}</span>
            </li>
          );
        })}
      </ul>
      <div className="divider"></div>
      <p className="text-4xl font-bold font-heading text-center">
        {props.price}
        <span
          className={`text-sm ${props.price === 'Free forever' ? 'hidden' : ''}`}
        >
          /month
        </span>
      </p>
      <p className="text-xs text-center">{props.priceDescription}</p>
      <button
        className="btn w-full btn-ghost flex m-auto mt-4"
        onClick={props.buttonOnClick}
      >
        This is your current plan
      </button>
    </div>
  );
}

function BasePlan(props: Props) {
  return (
    <div className="mt-4 sm:mt-0 rounded-md mx-4 border border-main-letters p-4 max-w-xs bg-main-backgroundFooter relative">
      <p className="mt-8"></p>
      {props.name === 'Standard' ? (
        <div className="bg-main-letters w-full absolute top-0 left-0 p-2 rounded-t-sm">
          <p className="text-main-backgroundFooter font-bold text-sm">
            Best Value
          </p>
        </div>
      ) : (
        <></>
      )}
      <p className="text-main-letters font-heading text-3xl">{props.name}</p>
      <p className="my-4 text-sm">{props.description}</p>
      <ul className="text-sm text-left list-disc list-inside text-main-letters">
        {props.features.map((feature, idx) => {
          return (
            <li key={idx}>
              <span className="text-white">{feature}</span>
            </li>
          );
        })}
      </ul>
      <div className="divider divider-primary"></div>
      <p className="text-4xl font-bold font-heading text-center">
        {props.price}
        <span
          className={`text-sm ${props.price === 'Free forever' || props.name == 'Lifetime' ? 'hidden' : ''}`}
        >
          /month
        </span>
      </p>
      <p className="text-xs text-center">{props.priceDescription}</p>
      <button
        className="btn w-full btn-primary text-white flex m-auto mt-4"
        onClick={() => props.buttonOnClick()}
      >
        {props.buttonText}
      </button>
      {props.modal}
    </div>
  );
}

export function StarterPlan(props: { billingInfo: BillingInfo | undefined }) {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  let isDisabled = false;

  const planProps = {
    name: 'Starter',
    description: 'Try out for free.',
    features: [
      'Track 3 URLs',
      'Checks every 12 hours',
      'Regular keywords',
      'Email notifications',
    ],
    price: 'Free forever',
    priceDescription: 'No credit Plan needed.',
    buttonText: 'Get started',
    buttonOnClick: () => navigate('/signup'),
    modal: <></>,
  };

  const downgradeToStarterModal = (
    <BaseModal
      id="downgrade_to_starter_modal"
      heading="Downgrade to Starter Plan"
      text={
        <span>
          By downgrading your subscription, you will be downgraded to Starter
          plan starting from the next billing cycle.
        </span>
      }
      onConfirm={async () => {
        const response = await apiCall(
          api.cancelSubscription(),
          'Downgrade succesful',
        );
        if (response.error) {
          return;
        }
        return setTimeout(() => navigate(0), 500);
      }}
    />
  );

  if (props.billingInfo) {
    if (
      props.billingInfo.planIs(PlanId.DEFAULT) ||
      props.billingInfo.planIs(PlanId.EARLY_ADOPTER) ||
      props.billingInfo.planIs(PlanId.DEV_INFINITE)
    ) {
      planProps.buttonText = 'This is your current plan';
      planProps.buttonOnClick = () => {};
      isDisabled = true;
    } else if (props.billingInfo.downgradePending()) {
      planProps.buttonText = 'Downgrade Pending';
      planProps.buttonOnClick = () => {};
    } else {
      planProps.buttonText = 'Downgrade to Starter';
      planProps.modal = downgradeToStarterModal;
      planProps.buttonOnClick = () => {
        (
          document.getElementById('downgrade_to_starter_modal') as any
        ).showModal();
      };
    }
  }

  if (isDisabled) {
    return <DisabledPlan {...planProps} />;
  }

  return <BasePlan {...planProps} />;
}

export function EssentialsPlan(props: {
  billingInfo: BillingInfo | undefined;
}) {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  let isDisabled = false;

  const planProps = {
    name: 'Essentials',
    description: 'For a peace of mind.',
    features: [
      'Track 10 URLs',
      'Checks every 8 hours',
      'Smart keywords',
      'Email notification',
    ],
    price: '$4.99',
    priceDescription: 'Cancel anytime.',
    buttonText: 'Get started',
    buttonOnClick: () => navigate('/signup?next=/pricing'),
    modal: <></>,
  };

  const downgradeToEssentialsModal = (
    <BaseModal
      id="downgrade_to_essentials_modal"
      heading="Downgrade to Essentials Plan"
      text={
        <span>
          When downgrading to Essentials Plan, we will issue a credit which will
          be applied in order to reduce the next invoice. <br />
          <br />{' '}
          <span className="font-bold">Downgrade happens immediately</span>.
        </span>
      }
      onConfirm={async () => {
        const response = await apiCall(
          api.changePlan(PlanId.ESSENTIALS),
          'Downgrade succesful',
        );
        if (response.error) {
          return;
        }
        return setTimeout(() => navigate(0), 500);
      }}
    />
  );

  if (props.billingInfo) {
    if (props.billingInfo.planIs(PlanId.LIFETIME)) {
      return <></>;
    }
    planProps.buttonText = 'This is your current plan';
    planProps.buttonOnClick = () => {};
    isDisabled = true;
  }

  if (isDisabled) {
    return <DisabledPlan {...planProps} />;
  }

  return <BasePlan {...planProps} />;
}

export function StandardPlan(props: { billingInfo: BillingInfo | undefined }) {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  let isDisabled = false;

  const planProps = {
    name: 'Standard',
    description: 'If you track sites often.',
    features: [
      'Track 12 URLs',
      'Checks every 2 hours',
      'Smart keywords',
      '3 notification channels',
    ],
    price: '$7.99',
    priceDescription: 'Cancel anytime.',
    buttonText: 'Get started',
    buttonOnClick: () => navigate('/signup?next=/pricing'),
    modal: <></>,
  };

  if (props.billingInfo) {
    if (props.billingInfo.planIs(PlanId.LIFETIME)) {
      // Early return since there is no point in showing other plans if user has lifetime plan
      return <></>;
    } else if (props.billingInfo.planIs(PlanId.STANDARD)) {
      planProps.buttonText = 'This is your current plan';
      planProps.buttonOnClick = () => {};
      isDisabled = true;
    } else {
      planProps.buttonText = 'Upgrade to Standard';
      planProps.buttonOnClick = async () => {
        (window as any).LemonSqueezy.Url.Open(await GetStandardPlanUrl());
      };
    }
  }

  if (isDisabled) {
    return <DisabledPlan {...planProps} />;
  }

  return <BasePlan {...planProps} />;
}

export function LifetimePlan(props: { billingInfo: BillingInfo | undefined }) {
  const navigate = useNavigate();

  let isDisabled = false;

  const planProps = {
    name: 'Lifetime',
    description: 'Pay once. Use forever.',
    features: [
      'Track 15 URLs',
      'Checks every 2 hours',
      'Smart keywords',
      '3 channels',
    ],
    price: '$87.99',
    priceDescription: 'No additional charges. Ever.',
    buttonText: 'Get started',
    buttonOnClick: () => navigate('/signup?next=/pricing'),
    modal: <></>,
  };

  const upgradeToLifetimeModal = (
    <BaseModal
      id="upgrade_to_lifetime_modal"
      heading="Upgrade to Lifetime Plan"
      text={
        <span>
          When upgrading to the Lifetime Plan, you&apos;ll get instant access to
          all benefits! Please note, our payment provider will charge for the
          rest of the month on your current plan, but we appreciate your
          understanding.
          <br />
          <br />
          <span className="font-bold">Upgrade happens immediately</span>.
        </span>
      }
      onConfirm={async () => {
        (window as any).LemonSqueezy.Url.Open(await GetLifetimePlanUrl());
        (document.getElementById('upgrade_to_lifetime_modal') as any).close();
      }}
    />
  );

  if (props.billingInfo) {
    if (props.billingInfo.planIs(PlanId.LIFETIME)) {
      planProps.buttonText = 'This is your current plan';
      planProps.buttonOnClick = () => {};
      isDisabled = true;
    } else if (
      props.billingInfo.planIs(PlanId.STANDARD) ||
      props.billingInfo.planIs(PlanId.ESSENTIALS)
    ) {
      planProps.buttonText = 'Upgrade to Lifetime';
      planProps.modal = upgradeToLifetimeModal;
      planProps.buttonOnClick = () => {
        (
          document.getElementById('upgrade_to_lifetime_modal') as any
        ).showModal();
      };
    } else {
      planProps.buttonText = 'Upgrade to Lifetime';
      planProps.buttonOnClick = async () => {
        (window as any).LemonSqueezy.Url.Open(await GetLifetimePlanUrl());
      };
    }
  }

  if (isDisabled) {
    return <DisabledPlan {...planProps} />;
  }

  return <BasePlan {...planProps} />;
}
