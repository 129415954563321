import React, { useEffect, useState } from 'react';
import useQueryParam from '../../hooks/UseQueryParam';
import { useNavigate } from 'react-router-dom';
import useApiCall from '../../hooks/UseApiCall';
import { parseSiteHistory } from '../../api';
import LoadingBar from '../../components/LoadingBar';
import { ChangeKind, DiffData, ParsedSiteHistory } from '../../api/types';
import HelmetComponent from '../../components/HelmetComponent';

function diffFormat(diffData: DiffData) {
  let header;
  let content;

  if (diffData.kind == 'ChangeKind.ADDED') {
    header = (
      <div className="border-b border-scraper-added text-scraper-added">
        <p>Added:</p>
      </div>
    );
    content = (
      <div className="p-0.5 lg:p-4 grid grid-cols-2">
        <div className="p-0.5 col-span-2">
          <p className="font-bold mb-4">New content:</p>
          {diffData.changes.flatMap((change, i) => [
            formatChange(i, change.kind, change.new),
          ])}
        </div>
      </div>
    );
  } else if (diffData.kind == 'ChangeKind.REMOVED') {
    header = (
      <div className="border-b border-scraper-removed text-scraper-removed">
        <p>Removed:</p>
      </div>
    );
    content = (
      <div className="p-0.5 lg:p-4 grid grid-cols-2">
        <div className="p-0.5 col-span-2">
          <p className="font-bold mb-4">Old content:</p>
          {diffData.changes.flatMap((change, i) => [
            formatChange(i, change.kind, change.old),
          ])}
        </div>
      </div>
    );
  } else {
    header = (
      <div className="border-b border-scraper-changed text-scraper-changed">
        <p>Modified:</p>
      </div>
    );
    content = (
      <div className="grid grid-cols-2 p-0.5 lg:p-4">
        <div className="p-0.5">
          <p className="font-bold mb-4">Old content:</p>
          {diffData.changes.flatMap((change, i) => [
            formatChange(i, change.kind, change.old),
          ])}
        </div>
        <div className="p-0.5">
          <p className="font-bold mb-4">New content:</p>
          {diffData.changes.flatMap((change, i) => [
            formatChange(i, change.kind, change.new),
          ])}
        </div>
      </div>
    );
  }

  return (
    <div className="text-left text-xs md:text-sm mt-2">
      {header}
      {content}
    </div>
  );
}

function formatChange(
  index: number,
  kind: ChangeKind,
  changes: string[] | undefined,
) {
  if (changes === undefined) {
    return <div key={index}></div>;
  }

  let className = {
    'ChangeKind.CHANGED': 'bg-scraper-changed text-black',
    'ChangeKind.ADDED': 'bg-scraper-added text-black',
    'ChangeKind.REMOVED': 'bg-scraper-removed text-black',
    'ChangeKind.SAME': '',
  }[kind];

  className += ' p-1 md:p-2';

  return (
    <div
      key={index}
      className={className}
      dangerouslySetInnerHTML={{ __html: changes.join('') }}
    ></div>
  );
}

export default function DiffPreview2() {
  const [diffModel, setDiffModel] = useState<ParsedSiteHistory | undefined>(
    undefined,
  );
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const queryParam = useQueryParam();
  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    if (diffModel !== undefined) {
      return;
    }

    const siteId = Number(queryParam('siteId'));
    const historyId = Number(queryParam('historyId'));

    if (Number.isNaN(siteId) || Number.isNaN(historyId)) {
      navigate('/404');
      return;
    }

    (async () => {
      const res = await apiCall(parseSiteHistory(siteId, historyId), undefined);
      if (res.error) {
        navigate('/404');
        return;
      }
      setDiffModel(res.data);
    })();
  });

  if (waitingForServerResponse || diffModel === undefined) {
    return (
      <div className="nm-default-page-small-content">
        <div className="">
          <p className="text-xl lg:text-3xl p-4">
            Give us a few moments to fetch site change...
          </p>
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-main-test1">
      <HelmetComponent
        title="History | Notify Me"
        description="History page."
      />
      <div className="text-center m-auto max-w-3xl p-4 text-white">
        <div className="bg-main-backgroundFooter rounded-3xl p-4 md:p-8">
          <p className="text-main-letters text-2xl md:text-4xl font-heading mb-4">
            Site changed:{' '}
            <a className="underline text-white" href={diffModel.site_url}>
              {diffModel.site_name}
            </a>
          </p>
          <div className="text-left text-xs pl-5">
            <p>
              <span className="text-scraper-added text-base md:text-lg mr-2">
                &#9679;
              </span>
              This is a newly{' '}
              <span className="text-black bg-scraper-added p-0.5"> added</span>{' '}
              text
            </p>
            <p>
              <span className="text-scraper-removed text-base md:text-lg mr-2">
                &#9679;
              </span>
              This text is now{' '}
              <span className="text-black bg-scraper-removed p-0.5">
                removed
              </span>
            </p>
            <p>
              <span className="text-scraper-changed text-base md:text-lg mr-2">
                &#9679;
              </span>
              <span>
                This text was{' '}
                <span className="text-black bg-scraper-changed p-0.5">
                  modified
                </span>
              </span>
            </p>
          </div>
          <div className="rounded-xl text-white p-5">
            {diffModel.changesets.map((data, i) => (
              <div key={i}>{diffFormat(data)}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
