import React, { useEffect, useState } from 'react';
import LoadingBar from '../../components/LoadingBar';
import { useBillingInfo } from '../../context/BillingInfoContext';
import { BillingInfo, PlanId } from '../../types';
import Plans from './AllPlans';
import toast from 'react-hot-toast';

export default function BillingAndPlans() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);
  const [currentPlan, setCurrentPlan] = useState('loading...');
  const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined>(
    undefined,
  );

  const { getBillingInfo } = useBillingInfo();

  const determineBillingCycleMessage = (billingInfo?: BillingInfo) => {
    const errorMessage = 'Unable to determine billing cycle.';
    if (!billingInfo) {
      return errorMessage;
    }
    if (billingInfo.planIs(PlanId.DEFAULT)) {
      return <span>This plan is free forever.</span>;
    }
    if (billingInfo.planIs(PlanId.EARLY_ADOPTER)) {
      return (
        <span>
          Since you are with us from the very beginning, this plan is free
          forever.
        </span>
      );
    }
    if (billingInfo.downgradePending()) {
      return (
        <span>{`You will be downgraded to Starter plan on: ${billingInfo.endsAt}`}</span>
      );
    }
    if (billingInfo.renews_at) {
      return (
        <span>
          {' '}
          Next billing cycle starts at:{' '}
          <span className="font-bold">{billingInfo.renewsAt}</span>{' '}
        </span>
      );
    }
    return errorMessage;
  };

  const determineNotificationChannelsMessage = (billingInfo?: BillingInfo) => {
    const errorMessage = 'Unable to determine billing cycle.';
    if (!billingInfo) {
      return errorMessage;
    }
    if (billingInfo.limits.number_of_notification_channels == 1) {
      return <span> email </span>;
    }
    return (
      <span>
        {' '}
        email + up to{' '}
        <span className="font-bold">
          {billingInfo.limits.number_of_notification_channels - 1}
        </span>{' '}
        other channels
      </span>
    );
  };

  useEffect(() => {
    (async () => {
      setWaitingForServerResponse(true);
      const billingInfo = await getBillingInfo(true);
      setWaitingForServerResponse(false);

      if (!billingInfo) {
        toast.error('Unable to get billing info.');
        return;
      }

      if (billingInfo.downgradePending()) {
        setCurrentPlan(`${billingInfo.name} (downgrade pending)`);
      } else {
        setCurrentPlan(billingInfo.name);
      }

      setBillingInfo(billingInfo);
      setWaitingForServerResponse(false);
    })();
  }, []);

  if (waitingForServerResponse) {
    return (
      <div className="m-auto text-center">
        <LoadingBar isLoading={true} />
      </div>
    );
  }

  const CardDetails = () => {
    if (billingInfo && billingInfo.planIs(PlanId.LIFETIME)) {
      return <></>;
    }

    return (
      <div className="bg-main-backgroundFooter p-8 rounded-xl mt-4 lg:mt-0">
        <p className="text-xl">Card Details</p>
        {billingInfo && billingInfo.card_brand ? (
          <div>
            <p className="mt-2 text-main-footerLinks text-xs">
              <span className="font-bold text-white">
                {/* TODO(@imafikus) this should re-render the component somehow */}
                {billingInfo.cardName}
              </span>{' '}
              ending with:&nbsp;
              <span className="font-bold text-white">
                {billingInfo.card_last_four}
              </span>
            </p>
            <p className="mt-2 text-main-footerLinks text-xs">
              Change card by clicking{' '}
              <span
                className="underline hover:no-underline cursor-pointer hover:text-white"
                onClick={() =>
                  (window as any).LemonSqueezy.Url.Open(
                    billingInfo.updatePaymentMethodUrl,
                  )
                }
              >
                here.
              </span>
            </p>
          </div>
        ) : (
          <div>
            <p className="mt-2 text-main-footerLinks text-xs">Card not set.</p>
          </div>
        )}
      </div>
    );
  };

  const BillingCycle = () => {
    if (billingInfo && billingInfo.planIs(PlanId.LIFETIME)) {
      return <></>;
    }
    return (
      <div className="bg-main-backgroundFooter p-8 rounded-xl mt-4 lg:mt-0">
        <p className="text-xl">Billing Cycle</p>
        <p className="mt-2 text-main-footerLinks text-xs">
          {determineBillingCycleMessage(billingInfo)}
        </p>
      </div>
    );
  };

  const PlanDetails = () => {
    if (billingInfo && billingInfo.planIs(PlanId.LIFETIME)) {
      return (
        <div className="text-sm bg-main-backgroundFooter p-8 rounded-xl mt-4 lg:mt-0">
          <p className="text-xl">Plan Details</p>
          <p className="mt-2 text-main-footerLinks text-xs">
            Current plan:{' '}
            <span className="font-bold text-white">{currentPlan}</span> (This
            plan has no expiration date, and it you will always have access to
            it&apos;s features forever.)
          </p>
          <p className="mt-2 text-main-footerLinks text-xs">
            Maximum number of sites:{' '}
            <span className="font-bold text-white">
              {billingInfo?.limits.max_site_count}
            </span>
          </p>
          <p className="mt-2 text-main-footerLinks text-xs">
            Notifications: {determineNotificationChannelsMessage(billingInfo)}
          </p>
          <p className="mt-2 text-main-footerLinks text-xs">
            Smart keywords:{' '}
            <span className="font-bold text-white">
              {billingInfo?.limits.textornado_keywords ? 'enabled' : 'disabled'}
            </span>
          </p>
          <p className="text-xs mt-4">
            15 sites not enough? - Reach out to{' '}
            <a
              className="underline hover:no-underline"
              href="mailto:tesic@notify-me.rs"
            >
              here
            </a>
          </p>
        </div>
      );
    }
    return (
      <div className="text-sm bg-main-backgroundFooter p-8 rounded-xl mt-4 lg:mt-0">
        <p className="text-xl">Plan Details</p>
        <p className="mt-2 text-main-footerLinks text-xs">
          Current plan:{' '}
          <span className="font-bold text-white">{currentPlan}</span>
        </p>
        <p className="mt-2 text-main-footerLinks text-xs">
          Maximum number of sites:{' '}
          <span className="font-bold text-white">
            {billingInfo?.limits.max_site_count}
          </span>
        </p>
        <p className="mt-2 text-main-footerLinks text-xs">
          Notifications: {determineNotificationChannelsMessage(billingInfo)}
        </p>
        <p className="mt-2 text-main-footerLinks text-xs">
          Smart keywords:{' '}
          <span className="font-bold text-white">
            {billingInfo?.limits.textornado_keywords ? 'enabled' : 'disabled'}
          </span>
        </p>
        <p className="mt-2 text-main-footerLinks text-xs">
          Subscription fee:{' '}
          <span className="font-bold text-white">
            ${billingInfo?.price_usd}
          </span>
        </p>
      </div>
    );
  };

  return (
    <div className="p-4 mt-2">
      <p className="nm-default-page-heading">Billing & Plans</p>
      <div className="rounded-xl max-w-4xl mx-auto  mt-8">
        <div className="p-6 m-auto">
          <div className="text-sm lg:grid lg:grid-cols-3 lg:gap-8">
            <PlanDetails />
            <CardDetails />
            <BillingCycle />
          </div>
        </div>
      </div>
      <Plans billingInfo={billingInfo as BillingInfo} />
    </div>
  );
}
