import React, { useEffect, useState } from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import { useNavigate } from 'react-router';
import { getTargetDiff } from '../../api';
import LoadingBar from '../../components/LoadingBar';
import useApiCall from '../../hooks/UseApiCall';
import useQueryParam from '../../hooks/UseQueryParam';

export default function DiffPreview() {
  const [diffPath, setDiffPath] = useState<string | undefined>(undefined);
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const queryParam = useQueryParam();
  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    const path = queryParam('diffPath');

    if (path === '') {
      navigate('/404');
      return;
    }
    (async () => {
      const res = await apiCall(getTargetDiff(path), undefined);
      if (res.error) {
        navigate('/404');
        return;
      }
    })();
    setDiffPath(path);
  }, []);

  if (waitingForServerResponse || diffPath === undefined) {
    return (
      <div className="nm-default-page-small-content">
        <div className="">
          <p className="text-xl lg:text-3xl p-4">
            Give us a few moments to fetch site change...
          </p>
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="nm-default-page lg:w-1/3">
      <HelmetComponent
        title="History | Notify Me"
        description="History page."
      />
      <div className="w-full h-full p-4">
        <iframe
          src={`/api/get_history?diffPath=${diffPath}`}
          width="100%"
          height="100%"
          onLoad={function (e) {
            const obj = e.target as any;
            obj.style.height =
              obj.contentWindow.document.documentElement.scrollHeight + 'px';
          }}
        />
      </div>
    </div>
  );
}
